import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Grid } from "react-loading-icons";

import StepPage from "../../components/step-page.js";
import { useRecoilValue } from "recoil";
import { orderIdState, usePagination } from "../../global_state.js";
import { getResultsForOrder } from "../../api.js";

import AppHeader from "../../components/app-header.js";

function OrderResult({ orderResult }) {
  return (
    <div className="order--result">
      <div className="order--result--content">
        <img src={orderResult.imageUrl} />
        <div className="label">{orderResult.styleName}</div>
      </div>
      <div
        className="button"
        onClick={() => window.open(orderResult.highResImageUrl, "_blank")}
        style={{ marginTop: 10 }}
      >
        View 4k Portrait
      </div>
    </div>
  );
}

export default function OrderResults() {
  const orderId = useRecoilValue(orderIdState);
  const [results, hasMore, { load, fetchMore }] = usePagination(
    async (cursor) => {
      const res = await getResultsForOrder(orderId, cursor);
      return {
        results: res.data.results,
        hasMore: res.data.hasMore,
      };
    }
  );

  useEffect(() => {
    load();
  }, [orderId]);

  return (
    <>
      <AppHeader showBack={false} />
      <StepPage
        title="Your Results!"
        description={
          <>
            Scroll down to view all of your results. Tap{" "}
            <strong>View 4k Portrait</strong> to open the high quality version.
          </>
        }
      >
        {/*<div className="button">Download Images Zip</div>*/}
        <InfiniteScroll
          dataLength={results.length}
          next={fetchMore}
          hasMore={hasMore}
          className="order--results"
        >
          {results.map((orderResult) => (
            <OrderResult orderResult={orderResult} key={orderResult.id} />
          ))}
        </InfiniteScroll>
      </StepPage>
    </>
  );
}
