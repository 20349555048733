import "./App.scss";
import { useLayoutEffect, useEffect, Suspense } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { createBrowserHistory } from "history";

import { Toaster } from "react-hot-toast";
import AnimatedLayout from "./components/animated-layout.js";

import historyState from "./history_state.js";
import LandingPage from "./pages/lander/index.js";
import OnboardPage from "./pages/onboard/index.js";
import SetupPage from "./pages/setup/index.js";
import ResultsPage from "./pages/results/index.js";

function RouteWrapper() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      {" "}
      <div>
        <Toaster />
      </div>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        {/*<Route path="/onboard/*" element={<OnboardPage />} />*/}
        <Route path="/setup/*" element={<SetupPage />} />
        <Route path="/results/*" element={<ResultsPage />} />
      </Routes>
      <div className="ios-overscroll" />
    </>
  );
}

function App() {
  const history = createBrowserHistory();
  const [state, setState] = useRecoilState(historyState);

  useEffect(() => {
    setState({ location: history.location, action: history.action });
  }, []);

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <BrowserRouter
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      <RouteWrapper />
    </BrowserRouter>
  );
}

export default App;
