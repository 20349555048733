import Section from "../../components/section.js";
import { useNavigate } from "react-router-dom";

import Button from "../../components/button.js";

function HowItWorksItem({ step, title, description }) {
  return (
    <div className="step--item">
      <div className="step--item--step">{step}</div>
      <div className="step--item--title">{title}</div>
      <div className="step--item--description">{description}</div>
      <img
        src="/assets/down-arrow.svg"
        className="step--item--arrow"
        alt="down arrow"
      />
    </div>
  );
}

export default function HowItWorks() {
  const navigate = useNavigate();
  return (
    <Section title="How it works" className="section--how-it-works">
      <div className="step--items">
        <HowItWorksItem
          step="1"
          title="Choose Styles"
          description={
            <>
              Choose <strong>14 styles</strong> you'd like included in your
              Magic Portrait Pack.
            </>
          }
        />
        <HowItWorksItem
          step="2"
          title="Upload Pictures"
          description={
            <>
              We will ask you to upload <strong>10-30 photos</strong> of
              yourself with varying angles & backgrounds. The more photos you
              provide the better the final result will be.
            </>
          }
        />
        <HowItWorksItem
          step="3"
          title="You're All Set!"
          description={
            <>
              Our advanced AI will use your photos & preferences to create your
              Magic Portraits. This process typically takes between{" "}
              <strong>30 minutes to 1 hour</strong>. When your order is ready
              you will be emailed a link to view your final portraits!
            </>
          }
        />
      </div>
      <Button label="Get Started" onClick={() => navigate("/setup")} />
    </Section>
  );
}
