import { ErrorBoundary } from "react-error-boundary";
import { Suspense } from "react";

export default function Status({
  loading = false,
  error = null,
  complete = false,
  children = null,
}) {
  return (
    <ErrorBoundary>
      <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
    </ErrorBoundary>
  );
  // hides and shows children when loaded
}
