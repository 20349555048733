import { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

import FeaturedStyles from "../../featured_styles.json";

import Button from "../../components/button.js";

export default function StylesSection() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("woman");
  const [styles, setStyles] = useState([]);

  useLayoutEffect(() => {
    setStyles(
      FeaturedStyles.filter(({ category }) => {
        if (selected === "all") {
          return true;
        }
        return category === selected;
      })
    );
  }, [selected]);

  return (
    <div className="section section--secondary">
      <div className="section--container">
        <div className="section--header">
          <div className="section--title">
            Transform into <strong>50+ styles</strong>
          </div>
          <div className="section--description">
            Simply upload a few photos & select 14 of your favorite styles.
            Magic Portrait will immediately get to work crafting 100+
            photorealistic avatars of you in your selected styles. We'll create
            8 variations for each style you select to ensure you get the best
            results possible! We have a wide selection of high quality styles
            that are constantly being added to. Your AI avatars will look just
            like you except in the styles you selected. Become anything you've
            ever wanted!
          </div>
        </div>
        <div className="section--content">
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
            }}
          >
            Try it out!
          </div>
          <div className="caption">Tap an input photo to see results!</div>
          <div className="style--grid--categories">
            <div
              className={`style--grid--category-2 ${
                selected === "woman" ? "selected" : ""
              }`}
              onClick={() => setSelected("woman")}
            >
              <img src="/assets/woman-input.jpg" />
            </div>
            <div
              className={`style--grid--category-2 ${
                selected === "man" ? "selected" : ""
              }`}
              onClick={() => setSelected("man")}
            >
              <img src="/assets/input-man.jpg" />
            </div>
          </div>
          <img
            src="/assets/down-arrow.svg"
            style={{ height: "20px", width: "20px" }}
          />
          <div className="style--grid">
            {styles.map((style) => (
              <div className="style--grid--item" key={style.image}>
                <img
                  src={`https://cdn.magicportrait.ai/cdn-cgi/image/width=300,height=300/${style.image}`}
                />
                <div className="style--grid--item--label">{style.name}</div>
              </div>
            ))}
          </div>
          <Button label="Get Started" onClick={() => navigate("/setup")} />
        </div>
      </div>
    </div>
  );
}
