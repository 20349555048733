export default function StepPage({
  image,
  caption,
  title,
  description,
  children,
  floatingActionButton,
}) {
  return (
    <div
      className={`container content--inset ${
        floatingActionButton && "content--inset--bottom"
      }`}
    >
      <div className="section full-height">
        <div className="section--container">
          <div className="section--header">
            {image && <img className="section--image" src={image} />}
            {caption && (
              <div className="caption" style={{ fontWeight: 700 }}>
                {caption}
              </div>
            )}
            {title && <div className="section--title">{title}</div>}
            {description && (
              <div className="section--description">{description}</div>
            )}
          </div>
          <div className="section--content">{children}</div>
        </div>
      </div>
      {floatingActionButton && (
        <div className="bottom-toolbar">{floatingActionButton}</div>
      )}
    </div>
  );
}
