import { useState, useRef, useEffect, useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate, useLocation } from "react-router-dom";
import validator from "validator";
import { sha256 } from "js-sha256";

import { currentSessionState } from "../../global_state.js";
import { createOrderSession } from "../../api.js";

import Button from "../../components/button.js";
import Section from "../../components/section.js";

// todo: use Section widget instead of StepPage
// and take copy from onboard/email

export default function Email() {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [ready, setReady] = useState(false);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const setSession = useSetRecoilState(currentSessionState);

  const emailRef = useRef(null);
  const confirmEmailRef = useRef(null);

  const onChange = useCallback((e) => {
    if (emailRef.current.value === confirmEmailRef.current.value) {
      if (emailRef.current.value) {
        if (validator.isEmail(emailRef.current.value)) {
          setReady(true);
          setError(null);
          setEmail(emailRef.current.value);
          window.ttq.identify({
            sha256_email: sha256(emailRef.current.value),
          });
        } else {
          setReady(false);
          setError("Invalid email");
        }
      }
    } else {
      setReady(false);
      setError("Emails do not match");
    }
  });

  useEffect(() => {
    emailRef?.current?.focus();
  }, []);

  return (
    <Section
      title="Enter Your Email!"
      description="We will use your email to send you your results once setup is complete."
      fullHeight={true}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <input
          placeholder="Email"
          style={{ marginBottom: "10px" }}
          ref={emailRef}
          onChange={onChange}
        />
        <input
          placeholder="Confirm Email"
          ref={confirmEmailRef}
          onChange={onChange}
        />
      </div>
      {error && (
        <div className="caption" style={{ color: "#FF6693" }}>
          <strong>{error}</strong>
        </div>
      )}
      <Button
        loading={loading}
        disabled={!ready || loading}
        label="Continue"
        onClick={async () => {
          if (!email || !ready) {
            return;
          }
          setLoading(true);
          try {
            const session = await createOrderSession(email);
            setSession(session.data.session);
            navigate(`/setup/category${location.search}`);
          } catch (e) {
            setLoading(false);
          }
        }}
      />
      <div className="caption" style={{ maxWidth: "400px" }}>
        By clicking continue you agree to our{" "}
        <strong
          style={{ pointer: "cursor" }}
          onClick={() =>
            window.open(
              "https://cdn.magicportrait.ai/legal/termsofservice.pdf",
              "_blank"
            )
          }
        >
          Terms of Service
        </strong>{" "}
        &{" "}
        <strong
          style={{ pointer: "cursor" }}
          onClick={() =>
            window.open(
              "https://cdn.magicportrait.ai/legal/privacypolicy.pdf",
              "_blank"
            )
          }
        >
          Privacy Policy
        </strong>
        . You must be <strong>18 years or older</strong> to proceed.
      </div>
    </Section>
  );
}
