export default function Footer() {
  return (
    <div className="section section--footer">
      <div className="section--container">
        <div className="section--header">
          <img
            src="/assets/logo.svg"
            alt="magicportrait"
            style={{ height: "35px" }}
          />
        </div>
        <div className="section--content">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "10px",
            }}
          >
            <div
              className="footer--link"
              onClick={() =>
                window.open(
                  "https://cdn.magicportrait.ai/legal/termsofservice.pdf",
                  "_blank"
                )
              }
            >
              Terms of Service
            </div>
            <div
              className="footer--link"
              onClick={() =>
                window.open(
                  "https://cdn.magicportrait.ai/legal/privacypolicy.pdf",
                  "_blank"
                )
              }
            >
              Privacy Policy
            </div>
            <div
              className="footer--link"
              onClick={() => window.open("mailto:contact@magicportrait.ai")}
            >
              Contact Us
            </div>
          </div>
          <div className="caption">
            © 2022 MakeShift.AI LLC. All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
