import { useState } from "react";
import { atom, selector } from "recoil";
import { syncEffect } from "recoil-sync";
import { array, string, nullable } from "@recoiljs/refine";
import { getCheckoutSession, getOrderById } from "./api.js";

// Checkout Sessions

export const sessionIdState = atom({
  key: "session_id",
  effects: [
    syncEffect({
      refine: string(),
    }),
  ],
  default: null,
});

export const currentSessionState = atom({
  key: "current_session",
  default: null,
});

// Order Status

export const orderIdState = atom({
  key: "order_id",
  effects: [
    syncEffect({
      refine: string(),
    }),
  ],
  default: null,
});

export const currentOrderState = selector({
  key: "current_order",
  get: async ({ get }) => {
    try {
      const session = get(currentSessionState);
      const orderId = get(orderIdState);
      const id = session?.order_id || orderId;
      if (!id) {
        return null;
      }
      const response = await getOrderById(id);
      return response.data;
    } catch (err) {
      return null;
    }
  },
});

// Upload States

export const selectedCategoryState = atom({
  key: "category",
  default: null,
});

export const selectedFilesState = atom({
  key: "file_ids",
  default: [],
});

export const selectedStylesState = atom({
  key: "style_ids",
  default: [],
});

export const startedSetupSession = atom({
  key: "session_started",
  default: false,
});

// Pagination Hooks

export function usePagination(getPage = async () => {}) {
  const [cursor, setCursor] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [results, setResults] = useState([]);

  async function fetchNextPage() {
    try {
      setHasMore(false);
      const page = await getPage(cursor);
      setResults([...results, ...page.results]);
      setHasMore(page.hasMore);
      setCursor((cursor) => cursor + 1);
    } catch (err) {
      setHasMore(false);
    }
  }

  return [
    results,
    hasMore,
    {
      load: () => {
        setCursor(0);
        setResults([]);
        setHasMore(false);
        fetchNextPage();
      },
      fetchMore: fetchNextPage,
    },
  ];
}
