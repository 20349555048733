import { useNavigate } from "react-router-dom";

export default function AppHeader({ showBack = true }) {
  const navigate = useNavigate();
  return (
    <div className="top--toolbar">
      <div className="left">
        {showBack && (
          <img
            alt="back"
            src="/assets/back-arrow.svg"
            onClick={() => navigate(-1)}
          />
        )}
      </div>
      <div className="center">
        <img
          src="/assets/logo.svg"
          alt="magicportrait"
          style={{ height: "25px" }}
          onClick={() => navigate("/")}
        />
      </div>
      <div className="right"></div>
    </div>
  );
}
