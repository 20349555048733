import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import StepPage from "../../components/step-page.js";
import OptionSelector from "../../components/selector.js";
import Button from "../../components/button.js";

import { selectedCategoryState } from "../../global_state.js";

export default function ChooseCategory() {
  const location = useLocation();
  const navigate = useNavigate();
  const [selected, setSelected] = useRecoilState(selectedCategoryState);

  return (
    <StepPage
      title="Choose a Category"
      description="This describes how you will be portrayed in your results."
    >
      <OptionSelector
        selected={selected}
        options={[
          { name: "Man", value: "man" },
          { name: "Woman", value: "woman" },
        ]}
        onChange={(val) => {
          setSelected(val);
        }}
      />
      <Button
        label="Continue"
        disabled={!selected}
        onClick={() => {
          navigate(`/setup/styles${location.search}`);
        }}
      />
    </StepPage>
  );
}
