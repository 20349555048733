export default function Section({
  children,
  className,
  type,
  title,
  description,
  image,
  caption,
  fullHeight,
}) {
  return (
    <div
      className={`section ${type === "secondary" && "section--secondary"} ${
        fullHeight && "full-height"
      } ${className}`}
    >
      <div className="section--container">
        <div className="section--header">
          {image && <img className="section--image" src={image} />}
          {caption && (
            <div className="caption" style={{ fontWeight: 700 }}>
              {caption}
            </div>
          )}
          <div className="section--title">{title}</div>
          {description && (
            <div className="section--description">{description}</div>
          )}
        </div>
        <div className="section--content">{children}</div>
      </div>
    </div>
  );
}
