import { useState } from "react";

export default function OptionSelector({
  options = [],
  selected = null,
  onChange = () => {},
}) {
  return (
    <div className="options--selector">
      {options.map((option) => (
        <div
          className={`option ${selected === option.value && "selected"}`}
          key={option.value}
          onClick={() => {
            onChange(option.value);
          }}
        >
          <span>{option.name}</span>
          <img
            src="/assets/selected.png"
            alt="selected"
            style={{ opacity: selected === option.value ? 1 : 0 }}
          />
        </div>
      ))}
    </div>
  );
}
