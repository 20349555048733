import { useState, useRef, useEffect, useCallback } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { useNavigate, useLocation } from "react-router-dom";

import Button from "../../components/button.js";
import StepPage from "../../components/step-page.js";

import { getCheckoutSession } from "../../api.js";
import {
  currentOrderState,
  currentSessionState,
  sessionIdState,
} from "../../global_state.js";

export default function SetupComplete() {
  const sessionId = useRecoilValue(sessionIdState);
  const [session, setSession] = useRecoilState(currentSessionState);
  const order = useRecoilValue(currentOrderState);
  const [seconds, setSeconds] = useState(30);
  const navigate = useNavigate();
  const location = useLocation();

  const completed = order?.status === "completed";

  useEffect(() => {
    if (!session) {
      (async () => {
        const state = await getCheckoutSession(sessionId);
        setSession(state.data?.session || null);
      })();
    }
    const timer = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      if (completed) {
        navigate(`/results${location.search}`, { replace: true });
      } else {
        navigate("/", { replace: true });
      }
    }
  }, [seconds, completed]);

  return (
    <StepPage
      image="/assets/success.png"
      title={completed ? "Order Ready!" : "Setup Completed!"}
      description={
        completed ? (
          <>You will be redirected to your results shortly</>
        ) : (
          <>
            You will receive an email within the next <strong>1-4 hours</strong>{" "}
            with a link to view your results! If you don't see it please check
            your spam or promotions folder.
          </>
        )
      }
    >
      <div className="caption">
        <strong>Email sent to:</strong>
      </div>
      <div
        style={{
          fontSize: "20px",
          fontWeight: 700,
          color: "#666dff",
        }}
      >
        {session?.email}
      </div>
      <div className="caption">
        {!completed && (
          <>
            <strong>Order is processing...</strong>
            <br />
          </>
        )}
        <span>This page will redirect in {seconds} seconds...</span>
      </div>
      <div className="button--group">
        {completed && (
          <Button
            onClick={() =>
              navigate(`/results${location.search}`, { replace: true })
            }
            label="View Results"
          />
        )}
        <Button
          secondary
          onClick={() => window.open("mailto:contact@magicportrait.ai")}
          label="Contact Support"
        />
      </div>
    </StepPage>
  );
}
