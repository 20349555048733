import axios from "axios";
import Promise from "bluebird";

const api = axios.create({
  baseURL:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:8080"
      : "https://api.magicportrait.ai",
});

export const getOrderById = (id) => api.get(`/orders/${id}`);

export const getResultsForOrder = (id, page = 0) =>
  api.get(`/orders/${id}/results`, {
    params: {
      page,
    },
  });

export const getStyles = (category = null, page = 0) =>
  api.get(`/styles`, {
    params: {
      category,
      page,
    },
  });

export const getCheckoutSession = (sessionId) =>
  api.get("/checkout/session", {
    params: {
      sessionId,
    },
  });

export const createOrderSession = (email) =>
  api.post(`/checkout/create-session`, {
    email,
  });

export const startOrder = (orderId, category, styleIds = [], fileIds = []) =>
  api.post(`/orders/${orderId}/start`, {
    category,
    styles: styleIds.join(","),
    files: fileIds.join(","),
  });

export async function uploadImagesForOrder(orderId, files = []) {
  const result = await api.post(`/orders/${orderId}/uploads/sign`, {
    files: files.map(({ size, type }) => ({
      sizeBytes: size,
      contentType: type,
    })),
  });

  const { results } = result.data;

  return await Promise.map(results, async ({ id, url }, i) => {
    const file = files[i];
    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
        "Content-Length": file.size,
      },
      body: file,
    });
    return id;
  });
}

export default api;
