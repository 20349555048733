export default function FAQ() {
  return (
    <div className="section">
      <div className="section--container">
        <div className="section--header">
          <div className="section--title">FAQ</div>
        </div>
        <div className="section--content">
          <div className="faq--item">
            <div className="faq--question">
              How closely will the portraits resemble me?
            </div>
            <div className="faq--answer">
              Our technology is quite advanced, sample results can be seen
              featured on our website.{" "}
              <strong>
                The better the photos you provide the closer your model will
                resemble you.
              </strong>{" "}
              We highly recommend you submit as high quality as possible photos
              and follow all the guidelines. Not every result will be useable
              hence why eight are rendered in each style.
            </div>
          </div>
          <div className="faq--item">
            <div className="faq--question">Are refunds available?</div>
            <div className="faq--answer">
              As soon as an order is confirmed your model will begin generating.
              This process endures costs and because of this we can not
              guarantee a full refund.
            </div>
          </div>
          <div className="faq--item">
            <div className="faq--question">Why is this service 18+ only?</div>
            <div className="faq--answer">
              Results are generated by cutting edge AI and may result in nudity
              or disfigurements. Under no circumstances should you upload images
              of anyone under 18.
            </div>
          </div>
          <div className="faq--item">
            <div className="faq--question">
              How long will it take to receive your avatars?
            </div>
            <div className="faq--answer">
              Average time to produce results is currently around 45 minutes.
              Generations may take up to 24 hours in extremely rare cases.
            </div>
          </div>
          <div className="faq--item">
            <div className="faq--question">
              Can I request my images and AI model to be deleted?
            </div>
            <div className="faq--answer">
              Yes, by default we may store your images and model for a short
              period of time to provide future portrait styles at a
              significantly reduced price. If you are concerned at any time you
              can submit a request{" "}
              <a href="https://6lutbwu7oq5.typeform.com/to/R2utYoVd">here</a> to
              have us remove any photos submitted as well as your AI model.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
