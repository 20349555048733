import { Oval } from "react-loading-icons";

export default function Button({
  label,
  onClick,
  disabled,
  secondary,
  loading = false,
  style,
}) {
  return (
    <div
      className={`button ${disabled || loading ? "disabled" : ""} ${
        secondary ? "secondary" : ""
      }`}
      onClick={(e) => {
        if (disabled || loading) {
          return;
        }
        onClick(e);
      }}
      style={style}
    >
      {loading && (
        <Oval
          stroke="#fff"
          fill="#fff"
          width="20px"
          height="20px"
          style={{ marginRight: "10px" }}
        />
      )}
      {label}
    </div>
  );
}
