import { useNavigate } from "react-router-dom";
import Button from "../../components/button.js";

export default function CTA() {
  const navigate = useNavigate();
  return (
    <div className="section section--secondary">
      <div className="section--container">
        <div className="section--header">
          <div className="section--title">
            Ready to create your Magic Portraits?
          </div>
        </div>
        <div className="section--content">
          <Button
            label="Create Your AI Avatars"
            onClick={() => navigate("/setup")}
          />
        </div>
      </div>
    </div>
  );
}
