import { useRecoilValue, useRecoilState } from "recoil";
import { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import { currentOrderState, startedSetupSession } from "../../global_state.js";

import AppHeader from "../../components/app-header.js";
import Status from "../../components/status.js";

import StartPage from "./start.js";
import ChooseCategoryPage from "./category.js";
import ChooseStylesPage from "./styles.js";
import UploadPhotosPage from "./upload.js";
import CompletePage from "./complete.js";
import EmailPage from "./email.js";

function SetupContainer() {
  const location = useLocation();
  const navigate = useNavigate();
  const order = useRecoilValue(currentOrderState);

  useEffect(() => {
    if (order && order.status !== "not_started") {
      if (
        location.pathname !== "/setup/complete" &&
        location.pathname !== "/setup/complete/" &&
        (location.pathname === "/setup" || location.pathname === "/setup/")
      ) {
        navigate(`/setup/complete${location.search}`, { replace: true });
      }
    }
    // if current route is not "/" then redirect to "/" if inSession is false
  }, [order, location.pathname]);

  return (
    <Routes>
      <Route exact path="/" element={<StartPage />} />
      <Route exact path="/email" element={<EmailPage />} />
      <Route exact path="/category" element={<ChooseCategoryPage />} />
      <Route exact path="/styles" element={<ChooseStylesPage />} />
      <Route exact path="/upload" element={<UploadPhotosPage />} />
      <Route exact path="/complete" element={<CompletePage />} />
    </Routes>
  );
}

export default function Setup() {
  const location = useLocation();
  const navigate = useNavigate();
  const [inSession, setInSession] = useRecoilState(startedSetupSession);

  useEffect(() => {
    // we only want setup session to work
    // if user doesn't reload
    if (!inSession) {
      setInSession(true);
      if (
        location.pathname !== "/setup" &&
        location.pathname !== "/setup/" &&
        location.pathname !== "/setup/complete" &&
        location.pathname !== "/setup/complete/"
      ) {
        navigate(`/setup${location.search}`, { replace: true });
      }
    }
  }, []);

  return (
    <>
      <AppHeader />
      <Status>
        <SetupContainer />
      </Status>
    </>
  );
}
