import HeroSection from "./hero.js";
import HowItWorksSection from "./howitworks.js";
import StylesSection from "./styles.js";
import FAQSection from "./faq.js";
import CTASection from "./cta.js";
import Footer from "../../components/footer.js";

export default function Lander() {
  return (
    <div className="container">
      <HeroSection />
      <StylesSection />
      <HowItWorksSection />
      <FAQSection />
      <CTASection />
      <Footer />
    </div>
  );
}
