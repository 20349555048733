import { useNavigate, useLocation } from "react-router-dom";
import StepPage from "../../components/step-page.js";
import Button from "../../components/button.js";

export default function StartPage() {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <StepPage
      image="/assets/sample-art.png"
      title="Let's Get Started!"
      description="Creating your Magic Portrait is an easy 3 step process. You will get to choose 14 styles, each with 8 image variations. During this process you will be asked to upload between 10-30 images of yourself."
    >
      <Button
        label="Get Started"
        onClick={() => {
          navigate(`/setup/email${location.search}`);
        }}
      />
    </StepPage>
  );
}
