import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { useNavigate } from "react-router-dom";
import HALO from "vanta/dist/vanta.halo.min";

import Button from "../../components/button.js";

export default function HeroSection() {
  const navigate = useNavigate();
  const [vantaEffect, setVantaEffect] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        HALO({
          el: ref.current,
          THREE: THREE,
          mouseControls: false,
          touchControls: false,
          gyroControls: false,
          minHeight: 400.0,
          minWidth: 400.0,
          backgroundColor: 0x000000,
          amplitudeFactor: 0.5,
          size: 1.5,
          baseColor: 0x666dff,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  return (
    <div className="section section--hero">
      <div className="section--container">
        <div className="section--content">
          <img
            src="/assets/logo.svg"
            alt="magicportrait"
            style={{ height: "35px" }}
          />
          <div className="section--title">
            Create your own
            <div
              style={{
                fontSize: "40px",
                color: "#FFF",
                background: "#666dff",
                padding: "10px 20px",
                borderRadius: 15,
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Photorealistic
            </div>
            AI Avatars
          </div>
          <img
            style={{
              width: "400px",
            }}
            className="section--hero--image"
            src="/assets/sample-art.png"
            alt="magicportrait"
          />
          <Button
            label="Create Your AI Avatars Now"
            onClick={() => navigate("/setup")}
          />
          <div className="section--hero--price">
            <div className="prices">
              <div className="price">$9.99</div>
              <div className="comparable">$19.99</div>
            </div>
            <div className="cost-per">50% off with code CHRISTMAS!</div>
            {/*<div className="cost-per">
              that's only <strong>$0.10</strong> / avatar!
            </div>*/}
          </div>
          <div className="section--bullets">
            <div className="section--bullet">
              <img
                src="/assets/selected.png"
                alt="magicportrait"
                className="section--bullet--icon"
              />
              <div className="section--bullet--text">
                Get 100+ Photorealistic Avatars
              </div>
            </div>
            <div className="section--bullet">
              <img
                src="/assets/selected.png"
                alt="magicportrait"
                className="section--bullet--icon"
              />
              <div className="section--bullet--text">
                Choose from 50+ Unique Styles
              </div>
            </div>
            <div className="section--bullet">
              <img
                src="/assets/selected.png"
                alt="magicportrait"
                className="section--bullet--icon"
              />
              <div className="section--bullet--text">
                All Avatars in 4K Resolution
              </div>
            </div>
            <div className="section--bullet">
              <img
                src="/assets/selected.png"
                alt="magicportrait"
                className="section--bullet--icon"
              />
              <div className="section--bullet--text">
                ~45 Min Processing Time
              </div>
            </div>
          </div>
          {/*<img
            style={{
              width: "400px",
            }}
            className="section--hero--image"
            src="/assets/sample-art.png"
            alt="magicportrait"
          />*/}
        </div>
      </div>
      <div className="hero--background" ref={ref} />
      <div className="hero--overlay"></div>
    </div>
  );
}
