import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useRecoilState, useRecoilValue } from "recoil";
import { getStyles } from "../../api.js";

import StepPage from "../../components/step-page.js";
import Button from "../../components/button.js";
import {
  selectedStylesState,
  selectedCategoryState,
  usePagination,
} from "../../global_state.js";

function StyleItem({
  selected = false,
  disabled = false,
  style,
  key,
  onClick = () => {},
}) {
  return (
    <div
      key={key}
      className={`style-item ${selected && "selected"} ${
        disabled && !selected && "disabled"
      }`}
      onClick={onClick}
    >
      <img
        src={`https://cdn.magicportrait.ai/cdn-cgi/image/width=200,height=200/${style.thumbnailUrl}`}
        alt={style.name}
      />
      <div className="label">{style.name}</div>
    </div>
  );
}

export default function ChooseStyles() {
  const location = useLocation();
  const navigate = useNavigate();
  const category = useRecoilValue(selectedCategoryState);
  const [selected, setSelected] = useRecoilState(selectedStylesState);
  const [items, hasMore, { load, fetchMore }] = usePagination(
    async (cursor) => {
      const res = await getStyles(category, cursor);
      return {
        results: res.data.results,
        hasMore: res.data.hasMore,
      };
    }
  );

  useEffect(() => {
    load();
  }, [category]);

  return (
    <StepPage
      title="Choose Styles"
      description={
        <>
          Choose <strong>14 styles</strong> for your order
        </>
      }
      floatingActionButton={
        <Button
          label="Continue"
          onClick={() => {
            navigate(`/setup/upload${location.search}`);
          }}
          disabled={selected.length < 14}
        />
      }
    >
      <div className="chip">{selected.length} / 14 selected</div>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMore}
        hasMore={hasMore}
        className="choose-styles-grid"
      >
        {items.map((item) => (
          <StyleItem
            selected={selected.indexOf(item.id) > -1}
            key={item.id}
            style={item}
            disabled={selected.length >= 14}
            onClick={() => {
              if (selected.indexOf(item.id) === -1) {
                if (selected.length < 14) {
                  setSelected([...selected, item.id]);
                }
              } else {
                setSelected(selected.filter((id) => id !== item.id));
              }
            }}
          />
        ))}
      </InfiniteScroll>
    </StepPage>
  );
}
