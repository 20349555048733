import Promise from "bluebird";
import { useState, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import toast from "react-hot-toast";
import { uploadImagesForOrder, startOrder } from "../../api.js";
import { useNavigate, useLocation } from "react-router-dom";

import Button from "../../components/button.js";
import StepPage from "../../components/step-page.js";

import {
  selectedFilesState,
  selectedCategoryState,
  currentSessionState,
  selectedStylesState,
} from "../../global_state.js";

export default function Upload() {
  const location = useLocation();
  const navigate = useNavigate();

  const [fileIds, setFileIds] = useRecoilState(selectedFilesState);
  const category = useRecoilValue(selectedCategoryState);
  const session = useRecoilValue(currentSessionState);
  const styleIds = useRecoilValue(selectedStylesState);

  const [buttonStatus, setButtonStatus] = useState("Upload Photos");
  const [loading, setLoading] = useState(false);
  const [uploads, setUploads] = useState([]);
  const inputFiles = useRef(null);

  async function onChange(e) {
    try {
      setLoading(true);
      setButtonStatus("Uploading...");

      const files = inputFiles.current.files || [];

      if (files.length < 10) {
        throw new Error("At least 10 photos are required.");
      }

      if (files.length > 30) {
        throw new Error("At most 30 photos are allowed.");
      }

      for (const file of files) {
        if (file.size > 50 * 1024 * 1024) {
          throw new Error("Each photo must be less than 50MB");
        }

        if (
          ![
            "image/jpeg",
            "image/png",
            "image/heic",
            "image/heif",
            "image/webp",
          ].includes(file.type)
        ) {
          throw new Error("Photo type must be JPEG, PNG, WEBP, or HEIC");
        }
      }

      const _fileIds = await uploadImagesForOrder(
        session.order_id,
        Array.from(files)
      );

      setFileIds(_fileIds);

      setButtonStatus("Processing...");

      const { data } = await startOrder(
        session.order_id,
        category,
        styleIds,
        _fileIds
      );

      if (data.success) {
        window.ttq.track("UploadComplete");
        setButtonStatus("Completed");
        // navigate(`/setup/complete${location.search}`);
        window.location.href = session.checkout_url;
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      toast.error(err?.message ?? "An error occured.");
      setUploads([]);
      setButtonStatus("Upload Photos");
      setLoading(false);
    }
  }

  return (
    <>
      <StepPage
        image="/assets/upload.png"
        title="Upload Photos"
        description={
          <>
            Upload <strong>10-30 photos</strong> of yourself. The photos you
            upload play an important role in the final results so choose wisely.
          </>
        }
        floatingActionButton={
          <Button
            label={buttonStatus}
            onClick={() => inputFiles.current.click()}
            loading={loading}
          />
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="caption"
            style={{ fontWeight: 700, marginBottom: "20px" }}
          >
            FOR BEST RESULTS
          </div>
          <div className="upload--requirement">
            <img src="/assets/closeup.png" />
            10 Close Ups
          </div>
          <div className="upload--requirement">
            <img src="/assets/fullbody.png" />3 Full Body
          </div>
          <div className="upload--requirement">
            <img src="/assets/sideprofile.png" />3 Side Profile
          </div>
          <div className="upload--requirement">
            <img src="/assets/chestup.png" />5 Chest Up
          </div>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="caption"
            style={{ fontWeight: 700, marginBottom: "20px" }}
          >
            DO NOT
          </div>
          <div className="upload--requirement">❌ Nobody under 18</div>
          <div className="upload--requirement">❌ No nudity</div>
          <div className="upload--requirement">❌ No black & white photos</div>
          <div className="upload--requirement">❌ No duplicate photos</div>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="caption"
            style={{ fontWeight: 700, marginBottom: "20px" }}
          >
            DO
          </div>
          <div className="upload--requirement">
            ✅ Only include yourself in photos
          </div>
          <div className="upload--requirement">
            ✅ Use photos with a variety of backgrounds, lighting, and clothing.
          </div>
          <div className="upload--requirement">
            ✅ If you lack photos take close ups of your face from different
            angles, with varying lighting, background, and clothing.
          </div>
          <div className="upload--requirement">
            ✅ The more photos you provide the better the results will be.
          </div>
        </div>
        <br />
        <div
          className="caption"
          style={{ fontWeight: 700, marginBottom: "20px" }}
        >
          WARNINGS
        </div>
        <div
          style={{ fontWeight: 700, textAlign: "center", marginBottom: "10px" }}
        >
          ⚠️ Glasses, headphones, and other accessories may show up in results.
          We recommended you use a variety of photos and limit accessories for
          best results.
        </div>
        <div
          style={{ fontWeight: 700, textAlign: "center", marginBottom: "10px" }}
        >
          ⚠️ In some cases the AI may generate erotic or shocking images. While
          this is not common, it is possible. If you do not want to see these
          images, please do not use the service.
        </div>
        <div
          style={{ fontWeight: 700, textAlign: "center", marginBottom: "20px" }}
        >
          ⚠️ After processing your photos we do not offer refunds. Please make
          sure to choose photos that fit the requirements to get the best
          result.
        </div>
        {uploads.map((upload, i) => (
          <img src={upload} key={i} height="100" width="100" />
        ))}
        <input
          type="file"
          id="file"
          ref={inputFiles}
          style={{ display: "none" }}
          multiple
          onChange={onChange}
        />
      </StepPage>
    </>
  );
}
